import { Message } from "../models/message.interface";

export class LoadMessages {
  public static readonly type = "[Messages] load";
  constructor(
    public skipCount: number,
    public maxResultsCount: number
  ) {}
}

export class AddMessage {
  public static readonly type = "[Messages] add";
  constructor(
    public message: Message,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}

export class UpdateMessage {
  public static readonly type = "[Messages] update";
  constructor(
    public message: Message,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}

export class RemoveMessage {
  public static readonly type = "[Messages] remove";
  constructor(
    public messageId: string,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}
