import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { API_URL } from "../../../../environments/settings";
import { EnvironmentService } from "../../../core/services/environment.service";
import { MessageFormComponent } from "./components/message-form/message-form.component";
import { Message } from "./models/message.interface";

@Injectable({ providedIn: "root" })
export class MessagesService {

	private apiURL: string;

	constructor(
		private http: HttpClient,
		private dialog: MatDialog,
		private env: EnvironmentService
	) {
		this.apiURL = `${this.env.getValueTransferState(API_URL)}/app/systemMessage`;
	}

	getMessages(skipCount: number, maxResultsCount: number) {
		const params: HttpParams = new HttpParams({
			fromObject: {
				MaxResultCount: maxResultsCount,
				SkipCount: skipCount
			}
		});
    return this.http.get<any>(this.apiURL, { params });
	}

	createMessage(message: Message) {
		return this.http.post<any>(this.apiURL, message).toPromise();
	}

	updateMessage(messageId: string, message: Message) {
		return this.http.put<any>(`${this.apiURL}/${messageId}`, message).toPromise();
	}

	deleteMessage(messageId: string) {
    return this.http.delete(`${this.apiURL}/${messageId}`).toPromise();
	}

	openMessageForm(message?: Message): void {
		this.dialog.open(MessageFormComponent, {
			width: "600px",
			autoFocus: false,
			data: {
				message
			}
		});
	}

}
