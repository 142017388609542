import { State, Action, StateContext, Selector } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import { AdminPagesStateDefaults } from "../../admin.state";
import { MessagesService } from "../messages.service";
import { AddMessage, LoadMessages, RemoveMessage, UpdateMessage } from "./messages.actions";
import { getUpdatedItems } from "src/app/shared/utils/utils";
import { MessagesStateModel } from "./messages.model";

@Injectable()
@State<MessagesStateModel>({
  name: "messages",
  defaults: AdminPagesStateDefaults
})
export class MessagesState {
  constructor(private _messagesService: MessagesService) {}

  @Selector() static messages(state: MessagesStateModel) { return state.items; }
  @Selector() static totalCount(state: MessagesStateModel) { return state.totalCount; }
  @Selector() static loading(state: MessagesStateModel) { return state.loading; }
  @Selector() static saving(state: MessagesStateModel) { return state.saving; }

  @Action(LoadMessages)
  loadMessages(ctx: StateContext<MessagesStateModel>, action: LoadMessages) {
    ctx.patchState({
      items: [],
      totalCount: 0,
      loading: true
    });

    return this._messagesService.getMessages(action.skipCount, action.maxResultsCount).pipe(
      tap(
        (response) => {
          ctx.patchState({
            items: response.items,
            totalCount: response.totalCount,
            loading: false
          });
        }
      )
    );
  }

  @Action(AddMessage)
  addMessage(ctx: StateContext<MessagesStateModel>, action: AddMessage) {
    ctx.patchState({ saving: true });

    return this._messagesService.createMessage(action.message).then(
      (item) => {
        action.callbackSuccess();
        const state = ctx.getState();
        ctx.patchState({
          items: [item, ...state.items],
          totalCount: state.totalCount + 1,
          saving: false
        });
      },
      (error) => {
        action.callbackError();
        ctx.patchState({ saving: false });
      }
    );
  }

  @Action(UpdateMessage)
  updateMessage(ctx: StateContext<MessagesStateModel>, action: UpdateMessage) {
    ctx.patchState({ saving: true });

    return this._messagesService.updateMessage(action.message.id, action.message).then(
      (response) => {
        action.callbackSuccess();
        const state = ctx.getState();
        ctx.patchState({
          items: getUpdatedItems(response, state.items),
          saving: false
        });
      },
      (error) => {
        action.callbackError();
        ctx.patchState({ saving: false });
      }
    );
  }

  @Action(RemoveMessage)
  removeMessage(ctx: StateContext<MessagesStateModel>, action: RemoveMessage) {
    return this._messagesService.deleteMessage(action.messageId).then(
      (response) => {
        action.callbackSuccess();
        const state = ctx.getState();
        ctx.patchState({
          items: state.items.filter(item => { return item.id !== action.messageId; }),
          totalCount: state.totalCount - 1
        });
      },
      (error) => {
        action.callbackError();
      }
    );
  }
}
